var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breakContract" },
    [
      _c(
        "van-tabs",
        {
          attrs: { animated: "", sticky: "", type: "card" },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: "卖料", name: "sell" } },
            [_c("sell", { ref: "sell" })],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "买料", name: "buy" } },
            [_c("buy", { ref: "buy" })],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabsLeftIcon", on: { click: _vm.back } },
        [_c("van-icon", { attrs: { name: "arrow-left" } })],
        1
      ),
      _vm.active == "sell"
        ? _c("div", { staticClass: "boxBtn", on: { click: _vm.settlement } }, [
            _c("div", { staticClass: "btn" }, [_vm._v("定价卖料-违约结算")])
          ])
        : _vm._e(),
      _vm.active == "buy"
        ? _c(
            "div",
            { staticClass: "boxBtn", on: { click: _vm.buysettlement } },
            [_c("div", { staticClass: "btn" }, [_vm._v("定价买料-违约结算")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }